export const getEventClassNames = (event, id = "") => {
  let arr = [];
  if (!event) {
    return arr;
  }
  if (event.disabled) {
    arr.push("disabled");
  }
  if (event.status === "personal") {
    arr.push("personal-event");
  }
  if (event.status === "cancelled") {
    arr.push("client-no-show");
  }
  if (event.status === "done") {
    arr.push("client-came");
  }
  if (event.isConfirmed) {
    arr.push("client-confirmed");
  }
  if (event.status === "new") {
    arr.push("new-order");
  }

  if (id === event.id) {
    arr.push("current-order");
  }

  if (event.isOverbooked) {
    arr.push("overbooked-event");
  }

  return arr.join(" ");
};
