<template>
  <div class="fixed-plugin">
    <div
      :class="{ active: props.isOpen }"
      class="shadow-lg card d-flex flex-column"
    >
      <div class="pb-0 pt-0 bg-transparent card-header shadow">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Налаштування</h5>
        </div>
        <div class="mt-4 float-end" @click="emit('close')">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="material-icons">clear</i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <div class="overflow-auto flex-grow-1 p-3">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["close"]);
</script>
<style scoped>
.fixed-plugin .card {
  width: 100%;
  max-width: 360px;
}
.shadow-lg.card.active {
  right: 0;
  padding: 0;
}
.shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
</style>
