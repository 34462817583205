<script setup>
import { useScheduleSetting } from "@/composables/scheduleSetting";
import { useModal } from "@/composables/common/modal";
import Multiselect from "vue-multiselect";
import SmallLoader from "@/views/shared/SmallLoader.vue";
import { computed, watch } from "vue";
import flatPickr from "vue-flatpickr-component";
import { ISODateToDay } from "@/utils/time";
import SelectApp from "@/components/common/SelectApp.vue";

const props = defineProps({
  isShow: {
    type: Boolean,
    default: false,
  },
  optionsItems: {
    type: Array,
    required: true,
  },
  date: {
    type: String,
    required: true,
  },
});

const dateForTitle = computed(() => {
  return ISODateToDay(props.date);
});

const optionsItems = computed(() => {
  return props.optionsItems;
});

watch(
  () => props.isShow,
  (val) => {
    if (val) {
      openModalThis();
    }
  }
);

const emit = defineEmits(["close", "update"]);

const {
  selectedItems: selectedRooms,
  isLoading,
  timeWork,
  dateRange,
  timeBreak,
  configFlatpickrTime,
  saveWorkingDays,
  reset,
} = useScheduleSetting({
  onSuccess: () => {
    closeModalThis();
    closeModal();
    emit("update");
  },
});

watch(
  () => props.date,
  () => {
    dateRange.value = props.date;
  },
  { immediate: true }
);

watch(
  () => props.optionsItems,
  (val) => {
    if (val.length === 1) {
      selectedRooms.value.push(val[0]);
    }
  }
);

const {
  openModal: openModalThis,
  closeModal: closeModalThis,
  modalRef: modalRef,
} = useModal({
  onClose: () => {
    emit("close");
  },
});

function closeModal() {
  closeModalThis();
  reset();
}

const isValid = computed(() => {
  if (!selectedRooms.value.length) {
    return false;
  }
  if (!timeWork.value.from || !timeWork.value.to) {
    return false;
  }
  return true;
});
</script>

<template>
  <div
    id="exampleModalConstructor"
    ref="modalRef"
    aria-hidden="true"
    aria-labelledby="exampleModalLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-weight-normal">
            Встановити графік: {{ dateForTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close text-dark"
            type="button"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group input-group-static">
            <div class="row">Оберіть майстрів (можна декілька)</div>
            <SelectApp
              v-model="selectedRooms"
              :settings="{
                options: optionsItems,
                trackBy: 'code',
                label: 'name',
                placeholder: 'Оберіть майстрів',
                closeOnSelect: false,
                taggable: true,
                multiple: true,
              }"
            />
          </div>
          <div class="row mt-3">
            <div class="row">Робочий час</div>
            <div class="col-5 text-center">
              <label class="d-block">Початок</label>
              <flat-pickr
                v-model="timeWork.from"
                :config="configFlatpickrTime"
                class="form-control datetimepicker"
                placeholder="Оберіть час"
              ></flat-pickr>
            </div>
            <div class="col-5 text-center">
              <label class="d-block">Кінець</label>
              <flat-pickr
                v-model="timeWork.to"
                :config="configFlatpickrTime"
                class="form-control datetimepicker"
                placeholder="--:--"
              ></flat-pickr>
            </div>
          </div>
          <div class="row mt-3">
            <div class="row">Перерва</div>
            <div class="col-5 text-center">
              <label class="d-block">Початок</label>
              <flat-pickr
                v-model="timeBreak.from"
                :config="configFlatpickrTime"
                class="form-control datetimepicker"
                placeholder="--:--"
              ></flat-pickr>
            </div>
            <div class="col-5 text-center">
              <label class="d-block">Кінець</label>
              <flat-pickr
                v-model="timeBreak.to"
                :config="configFlatpickrTime"
                class="form-control datetimepicker"
                placeholder="--:--"
              ></flat-pickr>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="closeModal"
          >
            закрити
          </button>
          <button
            :disabled="isLoading || !isValid"
            class="btn bg-gradient-primary"
            type="button"
            @click="saveWorkingDays()"
          >
            <SmallLoader v-if="isLoading" />
            <span v-else> Зберегти</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
