<script setup>
import { onMounted, ref, watch, computed, onBeforeUnmount } from "vue";
import { Scheduler, scheduler as schedulerGlobal } from "@dhx/scheduler";
import { DateTime } from "luxon";
import { useRoute, useRouter } from "vue-router";
import { getEventClassNames } from "@/utils/getClassesEvent";
import { useRouteQuery } from "vue-use-route-query";
import api from "@/services/api";
import { useStore } from "vuex";
import { useBreakpoints } from "@/composables/common/useBreakpoint";
import DefaultImg from "@/assets/img/defaultImg.svg";
import { hexToRGB } from "@/utils/colors";
import * as timeSlotConverter from "@/utils/timeSlotConverter";
import { useCompanyStore } from "@/storePinia/company";
import { storeToRefs } from "pinia";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";

const route = useRoute();
const router = useRouter();

const store = useStore();
const { isMobile } = useBreakpoints();
const companyStore = useCompanyStore();
const { companyIsHotel } = storeToRefs(companyStore);

const props = defineProps({
  items: { type: Array, required: true },
  events: { type: Array, required: true },
  date: { type: String, required: true },
  view: { type: String, required: true },
  showCancelledServiceOrders: { type: Boolean, default: false },
  heightCell: { type: [String, Number], default: 40 },
  smallerView: { type: Boolean, default: false },
  stepTime: { type: [String, Number], default: 1 },
  countDaysVisible: { type: [String, Number], default: 1 },
  showIconForInfoAboutPayment: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["updateDate"]);

const workingHours = computed(() => {
  return companyStore.getWorkingsHours();
});
const view = computed(() => {
  return props.countDaysVisible === 7 ? "week_unit" : "single_unit";
});

watch(workingHours, (val) => {
  scheduler.value.config.first_hour = val.start_time;
  scheduler.value.config.last_hour = val.end_time + 1;
  updateScheduler();
});
watch(
  () => props.showIconForInfoAboutPayment,
  () => {
    updateScheduler();
  }
);
watch(
  () => props.stepTime,
  (val) => {
    if (val) {
      scheduler.value.deleteMarkedTimespan(markedTimeSpan.value);
      addMarkedTimespan();
      scheduler.value.config.time_step = val;
      updateScheduler();
    }
  }
);
watch(
  () => props.heightCell,
  (val) => {
    scheduler.value.deleteMarkedTimespan(markedTimeSpan.value);
    addMarkedTimespan();
    scheduler.value.config.hour_size_px = val;
    updateScheduler();
  }
);
watch(
  () => props.items,
  (val) => {
    if (val.length > 0) {
      scheduler.value.updateCollection("list", val);
    } else {
      scheduler.value.updateCollection("list", [
        {
          key: "",
          label: "",
        },
      ]);
    }
  },
  { deep: true }
);
watch(view, (val) => {
  if (val === "week_unit") {
    scheduler.value.setCurrentView(date.value, "week_unit");
  } else {
    scheduler.value.setCurrentView(date.value, "single_unit");
  }
});
watch(
  () => props.date,
  (val) => {
    scheduler.value.setCurrentView(val, view.value);
  }
);

watch(
  () => props.events,
  (val) => {
    if (val) {
      scheduler.value.clearAll();
      scheduler.value.parse(val);
      updateScheduler();
    }
  }
);

const date = computed(() => {
  return props.date;
});

const markedTimeSpan = ref(null);
const schedulerRef2 = ref(null);
const scheduler = ref(null);

const serviceOrderId = useRouteQuery("serviceOrderId");
const idService = ref("");
const payload = ref({});

function getCountVisibleStaff(countDay = 1) {
  let result = 1;
  if (countDay === 1 && isMobile.value) {
    result = 3;
  }
  if (isMobile.value && countDay === 7) {
    result = 1;
  }
  if (!isMobile.value && countDay === 7) {
    result = 2;
  }
  if (!isMobile.value && countDay === 1) {
    result = 6;
  }
  if (countDay === 7 && props.items.length <= 2) {
    result = 1;
  }
  return 2;
}

let resolvePromiseEditEvent;

const eventData = ref(null);

const confirmEditPopupParams = ref({
  modalId: "modalSсhedulerEdit",
  modalTitle: `Зміна запису`,
  modalDescription: `Ви впевнені, що хочете змінити запис?`,
  isSuccess: false,
  confirmButtonColor: "primary",
  modalButtonCancelText: "Скасувати",
  modalButtonConfirmText: "Перенести",
  cancelHandler: () => {
    onCancelEventChange(eventData.value);
  },
  confirmHandler: () => onConfirmEventChange(eventData.value?.evNew),
});

const confirmPopupRef = ref(null);

function initScheduler() {
  scheduler.value = Scheduler.getSchedulerInstance();
  scheduler.value.skin = "material";
  scheduler.value.config.header = isMobile.value
    ? []
    : ["date", "today", "prev", "next"];

  scheduler.value.xy.scale_height = 60;
  // scheduler.value.xy.min_event_height = 44;

  scheduler.value.attachEvent("onBeforeTodayDisplayed", async () => {
    const today = DateTime.now().toISODate();

    const query = {
      ...route.query,
      serviceOrderId: "",
      preselectedDate: today,
    };
    await router.replace({ query });

    await router.push(`/ecommerce/calendar?preselectedDate=${today}`);

    return false;
  });

  scheduler.value.plugins({
    all_timed: true,
    quick_info: true,
    units: true,
    limit: true,
    container_autoresize: true,
    outerdrag: true,
  });
  scheduler.value.createUnitsView({
    name: "single_unit",
    property: "unit_id",
    skip_incorrect: true,
    round_position: true,
    size: isMobile.value ? 3 : 6,
    step: 1,
    days: 1,
    list: scheduler.value.serverList("list"),
  });
  scheduler.value.createUnitsView({
    name: "week_unit",
    property: "unit_id",
    skip_incorrect: true,
    round_position: true,
    size: isMobile.value ? 1 : 2,
    step: 1,
    days: 7,
    list: scheduler.value.serverList("list"),
  });

  scheduler.value.i18n.setLocale("ua");

  scheduler.value.config.all_timed = true;
  // scheduler.value.config.hour_size_px =
  //   (props.heightCell * 60) / parseInt(props.stepTime);
  scheduler.value.config.hour_size_px = props.heightCell;
  scheduler.value.config.first_hour = workingHours.value.start_time;
  scheduler.value.config.last_hour = workingHours.value.end_time + 1;
  scheduler.value.start_on_monday = true;
  scheduler.value.config.drag_create = false;
  scheduler.value.config.touch_tooltip = false;
  scheduler.value.config.mark_now = false;

  if (isMobile.value) {
    scheduler.value.xy.nav_height = 0;
  }
  scheduler.value.config.day_column_padding = 0;
  scheduler.value.config.touch_swipe_dates = true;
  scheduler.value.config.time_step = props.stepTime;
  scheduler.value.config.drag_resize = false;
  scheduler.value.config.dblclick_create = false;
  scheduler.value.config.edit_on_create = false;
  scheduler.value.config.overwrite_marked_timespans = false;
  scheduler.value.date.week_unit_start = scheduler.value.date.week_start;

  scheduler.value.attachEvent("onTemplatesReady", function (date) {
    addMarkedTimespan();
    scheduler.value.addMarkedTimespan({
      days: [0, 6],
      zones: "fullday",
      css: "scheduler_weekends",
    });
    let weekday = DateTime.now().weekday;
    if (weekday === 7) {
      weekday = 0;
    }

    scheduler.value.addMarkedTimespan({
      days: [weekday],
      zones: [DateTime.now().hour * 60 + DateTime.now().minute],
      css: "custom_now",
    });
  });

  scheduler.value.attachEvent("onClick", function (id, e) {
    if (!e.target.classList.contains("info-tooltip")) {
      router.push(`/service-orders/${id}`);
    }

    return false;
  });
  scheduler.value.attachEvent("onEmptyClick", function (date, e) {
    const dateObject = DateTime.fromJSDate(date);
    const hours = parseInt(dateObject.hour, 10);
    const minutes = parseInt(dateObject.minute, 10);
    const startAt = hours * 60 + minutes;
    let action_id = scheduler.value.getActionData(e).section;
    const params = `&serviceDate=${dateObject.toISODate()}&serviceTime=${startAt}`;

    router.push(`/service-orders/new?${params}&staffId=${action_id}`);

    return true;
  });

  scheduler.value.attachEvent(
    "onBeforeEventChanged",
    async function (ev, e, isNew, original) {
      eventData.value = { evNew: ev, evOld: original };

      const result = await new Promise((resolve) => {
        resolvePromiseEditEvent = resolve;
        confirmPopupRef.value?.showModal();
      });

      eventData.value = null;

      if (result) {
        return true;
      }

      return false;
    }
  );

  scheduler.value.attachEvent(
    "onBeforeViewChange",
    function (old_mode, old_date, mode, date) {
      if (date) {
        if (DateTime.fromJSDate(date).toISODate()) {
          emit("updateDate", DateTime.fromJSDate(date).toISODate());
        } else {
          emit("updateDate", date);
        }
      }

      return true;
    }
  );

  scheduler.value.templates.week_unit_date = function () {
    return getTemplateDate();
  };

  scheduler.value.templates.single_unit_date = function () {
    return getTemplateDate();
  };

  scheduler.value.templates.hour_scale = function (date) {
    let step = parseInt(props.stepTime);
    let html = "";
    for (let i = 0; i < 60 / step; i++) {
      html +=
        `<div style="height:${props.heightCell / (60 / step)}px;line-height:${
          props.heightCell / (60 / step)
        }px;">` +
        DateTime.fromJSDate(date).toFormat("HH:mm") +
        "</div>";
      date = scheduler.value.date.add(date, step, "minute");
    }
    return html;
  };

  scheduler.value.templates.single_unit_second_scale_date = function (date) {
    return getTemplateSecondScaleDate(date);
  };
  scheduler.value.templates.week_unit_second_scale_date = function (date) {
    return getTemplateSecondScaleDate(date);
  };
  scheduler.value.templates.single_unit_scale_text = function (
    key,
    label,
    unit,
    date
  ) {
    return getTemplateScaleText(key, label, unit, date);
  };
  scheduler.value.templates.week_unit_scale_text = function (
    key,
    label,
    unit,
    date
  ) {
    return getTemplateScaleText(key, label, unit, date);
  };

  scheduler.value.templates.event_class = function (start, end, event) {
    return getEventClassNames(event, serviceOrderId.value);
  };

  scheduler.value.renderEvent = function (container, event) {
    const isPersonal = event?.status === "personal";

    if (event.color) {
      container.classList.add("custom-color");
      container.style.setProperty("--color-event", hexToRGB(event.color));
    }

    let template = "";
    let icons = "";
    let prices = "";
    let times = "";
    times +=
      DateTime.fromJSDate(event.start_date).toFormat("HH:mm") +
      " - " +
      DateTime.fromJSDate(event.end_date).toFormat("HH:mm");
    icons +=
      "<i class='material-icons material-symbols-outlined text-info position-relative custom-icon info-tooltip'>info </i>";

    if (event.createdSource === "clientWebForm") {
      icons +=
        "<i class='material-icons position-relative custom-icon onlineBookingIcon'>computer</i>";
    }
    if (event.isAdditionalServices) {
      icons +=
        "<i  class='material-icons text-info position-relative custom-icon'>lan</i>";
    }

    let iconPrice = "";

    if (!isPersonal) {
      prices += "<div class='custom-event__prices'>";

      if (!props.showIconForInfoAboutPayment) {
        prices += "<div class='custom-event__price-all'>";
        prices += event.totalPaidCorrected;
        prices += "</div>";

        prices += "<div class='custom-event__price-rest'>";
        prices += event.totalToPay || 0;
        prices += "</div>";
      }
      if (!props.showIconForInfoAboutPayment) {
        prices += "</div>";
      }

      if (props.showIconForInfoAboutPayment) {
        const paymentAll = event.price === event.paidAmount;
        const paymentPartial = event.paidAmount > 0 && !paymentAll;
        if (paymentAll) {
          iconPrice +=
            '<i  class="material-symbols text-info text-xs material-symbols-outlined payment-icon position-relative custom-icon">monetization_on</i>';
        } else if (paymentPartial) {
          iconPrice +=
            '<i  class="material-symbols text-info text-xs material-symbols-outlined payment-icon position-relative custom-icon">attach_money</i>';
        }
      }
    }

    template += `<div class="dhx_event_move  custom-event">
                <div class="dhx_body custom-event__wrapper">
                <div class="custom-event__top d-flex justify-content-between align-items-center">
                <div class="custom-event__left d-flex align-items-center">
                 <div class="custom-event__icons">${icons}</div>
                <div class="custom-event__time">${times}</div>
                </div>
                <div class="custom-event__right">${iconPrice}</div>
                </div>
                 <div class="custom-event__body">
               `;
    if (!props.showIconForInfoAboutPayment) {
      template += prices;
    }

    if (event.clients) {
      const _clients = event.clients
        .map((item) => {
          return `${item?.nameAndSurname} ${item?.phoneNumber}`;
        })
        .join(", ");
      template += "<div class='custom-event__text'>" + _clients + "</div>";
    }

    if (event.services?.length) {
      template +=
        "<div class='custom-event__text'>" +
        event.services[0].serviceId.name +
        "</div>";
    }
    if (event.comment) {
      template += "<div class='custom-event__text'>" + event.comment + "</div>";
    }
    template += "</div> </div></div>";
    container.innerHTML = template;
    return true;
  };
  scheduler.value.init(schedulerRef2.value, date.value, view.value);
  scheduler.value.parse(props.events);

  scheduler.value.templates.quick_info_title = function (start, end, ev) {
    return null;
  };
  scheduler.value.templates.quick_info_content = function (start, end, ev) {
    const eventId = ev.id;
    const eventInfo = ev;
    const startTime = DateTime.fromJSDate(eventInfo.start_date).toFormat(
      "HH:mm"
    );
    const endTime = DateTime.fromJSDate(eventInfo.end_date).toFormat("HH:mm");
    const date = DateTime.fromJSDate(eventInfo.start_date).toFormat(
      "dd.MM.yyyy"
    );
    let template = "";
    template += `<div class="tooltip-info"><b>Дата: </b>${date}</div>`;
    template += `<div class="tooltip-info"><b>Час: </b>${startTime} - ${endTime}</div>`;
    if (eventInfo.clients) {
      const _clients = eventInfo.clients
        .map((item) => {
          return `${item?.nameAndSurname} ${item?.phoneNumber}`;
        })
        .join(", ");
      template += `<div class="tooltip-info"><b>Клієнт: </b>${_clients}</div>`;
    }
    if (eventInfo.comment) {
      template += `<div class="tooltip-info"><b>Коментар: </b>${eventInfo.comment}</div>`;
    }
    template += `<div class="tooltip-info"><b>Сума: </b>${eventInfo.price} грн</div>`;
    template += `<div class="tooltip-info"><b>Сплачено: </b>${eventInfo.totalPaidCorrected} грн</div>`;
    template += `<div class="tooltip-info"><b>До сплати: </b>${eventInfo.totalToPay} грн</div>`;

    return template;
  };
}

function updateScheduler(events = props.events) {
  scheduler.value.clearAll();
  scheduler.value.parse(events);

  let minStartTime = workingHours.value.start_time;
  let maxEndTime = workingHours.value.end_time + 1;

  events?.forEach((event) => {
    const _startTime = DateTime.fromJSDate(event.start_date).hour;
    const _endTime = DateTime.fromJSDate(event.end_date).hour + 2;

    if (_startTime < minStartTime) {
      minStartTime = _startTime;
    }
    if (_endTime <= 24 && _endTime > maxEndTime) {
      maxEndTime = _endTime;
    }

    if (
      DateTime.fromJSDate(event.start_date).day !==
      DateTime.fromJSDate(event.end_date).day
    ) {
      maxEndTime = 24;
      if (
        view.value === "week_unit" &&
        DateTime.fromJSDate(event.start_date).weekday !== 7
      ) {
        minStartTime = 0;
      }
    }
  });

  scheduler.value.config.first_hour = minStartTime;
  scheduler.value.config.last_hour = maxEndTime;

  scheduler.value.updateView();
}

function addMarkedTimespan() {
  let highlight_step = parseInt(props.stepTime);
  let highlight_html = "";
  let hours =
    scheduler.value.config.last_hour - scheduler.value.config.first_hour;
  let times = (hours * 60) / highlight_step;
  let height = scheduler.value.config.hour_size_px * (highlight_step / 60);
  for (let i = 0; i < times; i++) {
    highlight_html +=
      "<div class='highlighted_timespan' style='height: " +
      height +
      "px;'></div>";
  }

  markedTimeSpan.value = scheduler.value.addMarkedTimespan({
    days: "fullweek",
    zones: "fullday",
    html: highlight_html,
  });
}

async function changeEvent() {
  try {
    await api.serviceOrders.updateOne(idService.value, payload.value);
    store.commit("addToast", {
      title: "Збережено",
    });
  } catch (error) {
    store.commit("addToast", {
      title: "Виникла помилка. Спробуйте ще раз",
      type: "error",
    });
  } finally {
    payload.value = {};
    idService.value = "";
    updateScheduler();
  }
}

onMounted(() => {
  initScheduler();
});

onBeforeUnmount(() => {
  scheduler.value.deleteMarkedTimespan({
    days: [0, 6],
  });
  scheduler.value.destructor();
  scheduler.value = null;
});

function getTemplateDate() {
  let date1 = DateTime.fromJSDate(scheduler.value.getState().min_date);
  let date2 = DateTime.fromJSDate(scheduler.value.getState().max_date);
  let diff = date2.diff(date1, "days").toObject().days;

  if (diff < 3) {
    date1 = date1.setLocale("ua").toFormat(`EEE, dd MMMM`);
    date2 = date2.minus({ days: 1 }).setLocale("ua").toFormat(`EEE, dd MMMM`);
  } else {
    date1 = date1.setLocale("ua").toFormat("dd MMMM");
    date2 = date2.minus({ days: 1 }).setLocale("ua").toFormat("dd MMMM");
  }

  return date1 !== date2 ? `${date1} - ${date2}` : date1;
}

function getTemplateSecondScaleDate(date) {
  return DateTime.fromJSDate(date).setLocale("ua").toFormat("EEE dd.MM ");
}

function getTemplateScaleText(key, label, unit, date) {
  if (!unit?.key) {
    return "";
  }
  let template = "";
  const imgUrl = unit.image || DefaultImg;
  const classAvatar = unit.image ? "" : "default";
  template += `<div>
<div class="dhx_avatar-img ${classAvatar}">
<img src="${imgUrl}" alt="">
</div>
<div class="dhx_avatar-name">${label}</div></div>`;

  return template;
}

async function onConfirmEventChange(ev) {
  payload.value.serviceTime = timeSlotConverter.toNumber(
    DateTime.fromJSDate(ev.start_date).toISOTime()
  );
  idService.value = ev.id;
  payload.value.staffId = ev.unit_id;
  payload.value.serviceDate = DateTime.fromJSDate(ev.start_date).toISODate();

  const result = changeEvent();
  if (result) {
    resolvePromiseEditEvent(true);
  }
}

function onCancelEventChange(data) {
  if (data?.evOld) {
    let event = scheduler.value.getEvent(data.evOld.id);
    event.start_date = data.evOld.start_date;
    event.end_date = data.evOld.end_date;
    event.unit_id = data.evOld.unit_id;

    scheduler.value.updateEvent(event.id);
  }

  resolvePromiseEditEvent(false);
}
</script>

<template>
  <div class="scheduler-wrap-beauty position-relative flex-grow-1">
    <div v-if="props.items.length === 0" class="empty-text">
      <template v-if="props.countDaysVisible === 1">
        Сьогодні всі вихідні
      </template>
      <template v-if="props.countDaysVisible === 7">
        Цього тижня всі вихідні
      </template>
    </div>
    <div
      ref="schedulerRef2"
      class="scheduler-beauty scheduler--orders scheduler"
    ></div>
  </div>
  <BmnCustomPopup ref="confirmPopupRef" :params="confirmEditPopupParams" />
</template>
<style>
.dhtmlXTooltip.tooltip {
  font-size: 10px;
  padding: 5px;
}
</style>
<style lang="scss">
@import "~@dhx/scheduler/codebase/dhtmlxscheduler.css";

.scheduler-wrap-beauty {
  .empty-text {
    position: absolute;
    left: 50%;
    text-align: center;
    top: 70px;
    transform: translateX(-50%);
    z-index: 2;
    color: red;
    @media (max-width: 767px) {
      top: 30px;
    }
  }

  //height: calc(100vh - 100px);
  //@media (max-width: 991px) {
  //  height: calc(100vh - 200px);
  //}

  .dhx_scale_hour {
    padding: 0;

    &:first-child {
      div:first-child {
        opacity: 0;
      }
    }

    div {
      position: relative;
      width: 100%;
      transform: translateY(-50%);
      background: #fff;
      text-align: center;
    }
  }

  .dhx_marked_timespan {
    flex-direction: column;
  }

  .tooltip-info {
    font-size: 10px;
  }

  .dhx_matrix_now_time {
    z-index: 3;
  }

  .dhx_cal_container {
    height: 100% !important;
  }

  .dhx_marked_timespan.scheduler_weekends {
    background: rgba(255, 220, 40, 0.15);
  }

  .dhx_scale_bar {
    font-size: 12px;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 8px;
    }
  }

  .dhx_cal_event {
    font-size: 10px;
    font-weight: 400;
    line-height: 1.2;
    padding: 0;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    min-width: (calc((var(--width-cell, 40px))));
    text-overflow: ellipsis;
    border: none;

    display: flex;
    flex-direction: column;

    &.new-order {
      .custom-event {
        &__body {
          background: #d6eafc;
        }

        &__top {
          background: #d6eafc;
          filter: brightness(90%);
        }
      }
    }

    &.current-order {
      .custom-event {
        border: 1px solid red;
      }
    }

    &.client-confirmed {
      .custom-event {
        &__body {
          background: #d3c4e3;
        }

        &__top {
          background: #d3c4e3;
          filter: brightness(90%);
        }
      }
    }

    &.client-came {
      .custom-event {
        &__body {
          background: #b8dedb;
        }

        &__top {
          filter: brightness(90%);
          background: #b8dedb;
        }
      }
    }

    &.client-no-show {
      .custom-event {
        &__body {
          background: #f4b7b9;
        }

        &__top {
          background: #f4b7b9;
          filter: brightness(90%);
        }
      }
    }

    &.personal-event {
      .custom-event {
        &__body {
          background: #fbe6cf;
        }

        &__top {
          background: #fbe6cf;
          filter: brightness(90%);
        }
      }
    }

    &.custom-color {
      .custom-event {
        &__body {
          background-color: var(--color-event);
        }

        &__top {
          background: var(--color-event);
          filter: brightness(90%);
        }
      }
    }
  }

  .booking_status,
  .booking_paid {
    position: absolute;
    right: 5px;
  }

  .booking_status {
    top: 2px;
  }

  .booking_paid {
    bottom: 2px;
  }

  .dhx_cal_date {
    text-align: left;
    margin-left: 0 !important;
  }

  .dhx_cal_navline.dhx_cal_navline_flex {
    .dhx_cal_nav_button {
      background-color: #0f4432;
      border: none;
      font-size: 0.875rem;
      order: 3;
      min-width: 35px;
      height: 35px;
      box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11),
        0 2px 4px -1px rgba(0, 0, 0, 0.07);
      transition: all 0.15s ease-in;
      color: #fff;
      text-transform: capitalize;
      border-radius: 5px;
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      padding: 0;
      background-image: none;
      @media (max-width: 768px) {
        min-width: 28px;
        height: 28px;
      }

      &:hover {
        opacity: 0.9;
      }
    }

    .dhx_cal_prev_button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        background: url("../../assets/img/arrow-prev.svg") center / 100% 100%
          no-repeat;
        width: 16px;
        height: 16px;
      }
    }

    .dhx_cal_next_button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        background: url("../../assets/img/arrow-prev.svg") center / 100% 100%
          no-repeat;
        width: 16px;
        height: 16px;
        transform: rotate(180deg);
      }
    }

    .dhx_cal_today_button {
      padding: 5px 16px;
      margin-right: 10px;
      @media (max-width: 768px) {
        font-size: 12px;
        padding: 5px 10px;
      }
    }
  }

  .dhx_cal_navline .dhx_cal_date {
    font-size: 16px;
  }

  .dhx_matrix_scell {
    padding: 10px;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 10px;
      padding: 5px;
    }
  }

  .dhx_matrix_scell-name {
    text-align: left;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: flex;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .dhx_event_resize {
    filter: invert(0.3);
  }

  .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_date {
    text-align: left;
  }

  .onlineBookingIcon {
    color: orange;
  }

  .scheduler-beauty {
    --dhx-scheduler-font-family: Roboto, sans-serif;

    .custom_now {
      height: 1px;
      background: red;
      z-index: 3;
    }

    .dhx_scale_time_slot {
      pointer-events: none;
    }

    .highlighted_timespan {
      transition: all 0.2s cubic-bezier(0.5, 0.02, 0.13, 0.5);
      z-index: 1;
      width: 100%;
      position: relative;

      &:hover {
        background: lightcyan !important;
      }
    }

    .dhx_cal_header {
      height: 90px !important;
    }

    &.dhx_scheduler_single_unit {
      .dhx_cal_header {
        height: 70px !important;
      }
    }

    .dhx_scale_bar {
      //border-right: 1px solid red;
    }

    //.dhx_second_cal_header {
    //  height: 70px !important;
    //}
    .dhx_cal_next_button,
    .dhx_cal_prev_button {
      max-width: 30px;
      aspect-ratio: 1;
      top: 14px !important;
    }

    .dhx_cal_header {
      //&::after {
      //  content: "";
      //  position: absolute;
      //  background: #e0e0e0;
      //  bottom: 0;
      //  left: 0;
      //  width: 200%;
      //  height: 1px;
      //}
    }

    .dhx_cal_scale_placeholder {
      display: none !important;
    }

    .material-icons {
      font-size: 12px;
      margin-right: 4px;
    }

    .dhx_scale_hour {
      font-weight: 300;
      &:first-child {
        & > * {
          &:first-child {
            //opacity: 0;
          }
        }
      }

      //transform: translateY(-22px);
      //border: none;
    }

    .dhx_event_resize {
      display: none !important;
    }

    .dhx_cal_event {
      gap: 0px;
      background: transparent;
      --dhx-scheduler-event-text-font-size: 10px;
    }

    .dhx_cal_event .dhx_body {
      gap: 0px;
    }

    .custom-event {
      border-radius: 6px;
      flex: 1 1 auto;
      overflow: hidden;
      font-size: 10px;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;

      .dhx_body {
        background: transparent !important;
        padding: 0;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        color: #000;
      }

      &__top {
        display: flex;
        align-items: center;
        color: #000;
        padding: 2px 5px;
      }

      &__body {
        padding: 4px;
        flex: 1 1 auto;
      }

      &__time {
        flex: 1 1 auto;
      }

      &__text {
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;
        margin-top: -2px;
      }

      &__prices {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 500;
      }

      &__price-rest {
        color: red;
      }

      &__price-all {
        color: green;
      }
    }
  }

  .dhx_cal_header {
    .dhx_scale_bar:not(.dhx_second_scale_bar) {
      //border-top: 1px solid #e0e0e0;
      height: 70px !important;
    }
  }

  .dhx_second_cal_header {
    .dhx_scale_bar:not(.dhx_second_scale_bar) {
      //border-top: 1px solid #e0e0e0;
      height: 70px !important;
    }
  }

  .dhx_scale_bar {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > div {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex: 1 1 auto;
    }
  }

  .dhx_avatar-img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    overflow: hidden;

    &.default {
      background: #000;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      img {
        width: 15px;
        height: 15px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .dhx_avatar-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    font-weight: 400;
    width: 100%;
  }
}

.dhx_event_move {
  cursor: pointer;
}

.footer {
  display: none;
}
</style>
