<script setup>
import { onMounted, ref, watch } from "vue";
import { DateTime } from "luxon";
import { Ukrainian } from "flatpickr/dist/l10n/uk";
import api from "@/services/api";
import flatPickr from "vue-flatpickr-component";

const props = defineProps({
  isHotel: { type: Boolean, default: false },
  itemsEmpty: { type: Boolean, default: false },
});

const checkInOutdatePickerConfig = ref({
  altInput: true,
  altFormat: "Y-m-d",
  mode: "single",
  dateFormat: "Y-m-d",
  locale: Ukrainian,
  inline: false,
  disableMobile: true,
  onReady: checkIfTodaySelected,
  onValueUpdate: checkIfTodaySelected,
});

const checkInOutDate = ref(DateTime.now().toISODate());

function checkIfTodaySelected(date, dateStr, fp) {
  const todayStr = DateTime.now().toISODate();

  if (todayStr === dateStr) {
    fp._input.value = "Сьогодні";
  }
}

const checkIn = ref({});
const checkOut = ref({});
const stays = ref({});

async function getCheckInAndOutInfo(_, date) {
  if (!props.isHotel) {
    return;
  }
  try {
    const response = await api.serviceOrders.getCheckInAndOutInfo(date);
    checkIn.value = response.checkIn;
    checkOut.value = response.checkOut;
    stays.value = response.stays;
  } catch (err) {
    console.error(err);
    checkIn.value = {
      rooms: 0,
      guests: 0,
    };
    checkOut.value = {
      rooms: 0,
      guests: 0,
    };
    stays.value = {
      rooms: 0,
      guests: 0,
    };
  }
}

watch(
  () => props.isHotel,
  async (val) => {
    if (val) {
      await getCheckInAndOutInfo(null, DateTime.now().toISODate());
    }
  }
);
onMounted(async () => {
  await getCheckInAndOutInfo(null, DateTime.now().toISODate());
});
</script>
<template>
  <div class="mt-xl-3 container-fluid card-calendar-upper mb-1">
    <div class="row mt-4" v-if="isHotel && itemsEmpty">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body p-3 position-relative">
            <div class="col-12 mt-3">
              <div class="row">
                <div class="">
                  <div class="card card-blog card-plain">
                    <div class="card-header p-0 mt-n4 mx-3">
                      <a
                        class="d-block shadow-xl border-radius-xl img-room overflow-hidden h-100"
                      >
                        <img
                          :src="require('@/assets/img/room.jpg')"
                          alt="img-blur-shadow"
                          class="img-fluid shadow w-100 h-100 object-fit-cover"
                        />
                      </a>
                    </div>
                    <div class="card-body p-3">
                      <a href="javascript:;">
                        <h5>Створити номер</h5>
                      </a>
                      <p class="mb-4 text-sm">
                        Створіть ваш перший номер і додайте всю необхідну
                        інформацію, щоб розпочати користування
                      </p>
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <router-link
                          :to="{ path: '/hotel/onboarding/create-unit' }"
                          type="button"
                          class="btn btn-outline-primary btn-sm mb-0"
                          >Створити номер
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row availabilityScope" v-if="isHotel && !itemsEmpty">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body p-3 position-relative">
            <div class="row">
              <div class="col-4">
                <div class="wrap-calendar dropdown text-end">
                  <flat-pickr
                    class="btn btn-light form-control datetimepicker customDatePickerButton"
                    :config="checkInOutdatePickerConfig"
                    @on-change="getCheckInAndOutInfo"
                    v-model="checkInOutDate"
                  />
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-hover" id="datatable-search">
                  <thead class="thead-light text-start">
                    <tr>
                      <th
                        class="text-xs text-info font-weight-bold headerStyle"
                      >
                        Проживають
                      </th>
                      <th
                        class="text-xs text-capitalize text-success font-weight-bold headerStyle"
                      >
                        Заїзд
                      </th>
                      <th
                        class="text-xs text-danger font-weight-bold headerStyle"
                      >
                        Виїзд
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        class="text-xs font-weight-normal text-start contentStyle"
                      >
                        <span class="my-2 text-xs"
                          >Номерів: {{ stays.rooms }}</span
                        >
                      </td>
                      <td
                        class="text-xs font-weight-normal text-start contentStyle"
                      >
                        <span class="my-2 text-xs"
                          >Номерів: {{ checkIn.rooms }}</span
                        >
                      </td>
                      <td
                        class="text-xs font-weight-normal text-start contentStyle"
                      >
                        <span class="my-2 text-xs"
                          >Номерів: {{ checkOut.rooms }}</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-xs font-weight-normal text-start contentStyle"
                      >
                        <span class="my-2 text-xs"
                          >Гостей: {{ stays.guests }}</span
                        >
                      </td>
                      <td
                        class="text-xs font-weight-normal text-start contentStyle"
                      >
                        <span class="my-2 text-xs"
                          >Гостей: {{ checkIn.guests }}</span
                        >
                      </td>
                      <td
                        class="text-xs font-weight-normal text-start contentStyle"
                      >
                        <span class="my-2 text-xs"
                          >Гостей: {{ checkOut.guests }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// .availabilityScope {
//   @media (max-width: 768px) {
//     display: none;
//   }
// }

.card-calendar-upper :deep(.customDatePickerButton) {
  font-weight: 500;
  font-size: 0.5rem;
}

.headerStyle {
  padding: 0.75rem 0 !important;
}

.contentStyle {
  padding: 0.5rem 0 !important;
}
</style>
