<script setup>
import { watch, ref, computed, onBeforeUnmount } from "vue";
import { DateTime, Interval } from "luxon";
import { useSwipe } from "@vueuse/core";

const props = defineProps({
  date: {
    type: String,
    required: "true",
  },
});

const refCalendar = ref(null);
const isAnimationActive = ref(false);
const timer = ref(null);
const { direction } = useSwipe(refCalendar, {
  onSwipeEnd() {
    switch (direction.value) {
      case "right": {
        isAnimationActive.value = true;
        timer.value = setTimeout(() => {
          isAnimationActive.value = false;
        }, 1000);
        goToPrevWeek();
        break;
      }
      case "left": {
        isAnimationActive.value = true;
        timer.value = setTimeout(() => {
          isAnimationActive.value = false;
        }, 1000);
        goToNextWeek();
        break;
      }
    }
  },
});

onBeforeUnmount(() => {
  clearTimeout(timer.value);
});

const date = ref(null);
const today = DateTime.now().toISODate();
const emit = defineEmits(["changeDate"]);

const dates = ref([]);

const normalizeDates = computed(() => {
  return dates.value.map((item) => {
    return { dateIso: item, ...getDayFromIso(item) };
  });
});

watch(
  () => props.date,
  (newValue) => {
    if (newValue) {
      date.value = newValue;
      setTimeout(() => {}, 500);
    }
  },
  { immediate: true }
);
watch(
  date,
  () => {
    const firstDay = DateTime.fromISO(date.value).startOf("week");
    const lastDay = DateTime.fromISO(date.value).endOf("week");
    dates.value = Interval.fromDateTimes(firstDay, lastDay)
      .splitBy({
        days: 1,
      })
      .map((item) => DateTime.fromObject(item.s.c).toISODate());
  },
  { immediate: true }
);

function getDayFromIso(isoDate) {
  return {
    day: DateTime.fromISO(isoDate).toFormat("dd"),
    dayText: DateTime.fromISO(isoDate).setLocale("ua").toFormat("ccc"),
  };
}

function goToNextWeek() {
  const newDate = DateTime.fromISO(date.value).plus({ days: 7 }).toISODate();
  setDate(newDate);
}
function goToPrevWeek() {
  const newDate = DateTime.fromISO(date.value).minus({ days: 7 }).toISODate();
  setDate(newDate);
}
function setDate(date) {
  emit("changeDate", date);
}
function toToday() {
  emit("changeDate", today);
}
</script>
<template>
  <div v-if="props.date" class="calendar-day mb-2">
    <div
      @click="toToday"
      :class="{ active: date !== today }"
      class="calendar-day__current-day"
    >
      {{ getDayFromIso(today).day }}
    </div>
    <div
      ref="refCalendar"
      class="calendar-day__items"
      :class="{ active: isAnimationActive }"
    >
      <div
        class="name__item"
        :class="{
          active: day.dateIso === date,
          today: day.dateIso === today,
          weekend: day.dayText === 'сб' || day.dayText === 'нд',
        }"
        v-for="(day, index) of normalizeDates"
        ref="navItemRefs"
        :key="index"
        :id="day.dateIso"
        @click="setDate(day.dateIso)"
      >
        <span class="day-name">{{ day.dayText }}</span>
        <div class="date">{{ day.day }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calendar-day {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 16px;
  @media (max-width: 400px) {
    padding: 8px;
  }
}
.calendar-day__current-day {
  position: absolute;
  bottom: calc(100% - 10px);
  right: 16px;
  width: 50px;
  height: 50px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  background: #d9d9d9;
  @media (max-width: 400px) {
    bottom: calc(100% - 0px);
    width: 40px;
    height: 40px;
    font-size: 18px;
    border-radius: 10px;
  }
  &.active {
    background: #67ad5b;
    color: #fff;
    &::after {
      transform: translate(5px, -50%);
    }
  }

  &::after {
    content: "";
    right: 0;
    background: #d9d9d9;
    position: absolute;
    top: 50%;
    aspect-ratio: 1;
    transform: translate(0px, -50%);
    border-radius: 8px;
    height: calc(100% - 8px);
    z-index: -1;
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
  }
}
.calendar-day__items {
  border-radius: 12px;
  background: #272a30;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  &.active {
    animation: animation 0.3s linear;
  }
}

.calendar-day__item > .name {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  color: #8d969c;
}
.name__item {
  font-size: 16px;
  color: #fff;
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  @media (max-width: 400px) {
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 18px;
    flex-shrink: 0;
  }
  &.active {
    border-radius: 12px;
    background: #67ad5b;
    @media (max-width: 400px) {
      border-radius: 8px;
    }
  }
}

.calendar-day .weekend .day-name,
.calendar-day .weekend .date {
  color: #b55f3d !important;
}

@keyframes animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
</style>
